import React, { Suspense, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useRecoilState } from 'recoil';
import { useAsync } from 'react-use';

// components
import Loader from '../components/loader';

// lazy loaded containers
const Home = lazy(() => import('./home'));
const Product = lazy(() => import('./product'));
const Health = lazy(() => import('./health'));
const NotFound = lazy(() => import('./not-found'));

// state
import { ProductRangeAtom } from '../state/product-range';

const App = () => {
  const location = useLocation();
  const [productRanges, setProductRanges] = useRecoilState(ProductRangeAtom);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.replace('#', ''));
      if (element) element.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const { loading } = useAsync(async () => {
    if (productRanges.length === 0) {
      const response = await fetch('/data.json');
      const data = await response.json();
      if (data) {
        setProductRanges(data as EmissaryElixirs.ProductRange[]);
      }

      return data;
    }

    return productRanges;
  }, [productRanges, setProductRanges]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Helmet>
        <title>Emissary Elixirs</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Small, hand-crafted batches of premium vapour liquid. Based in Cape Town, South Africa."
        />
        <meta name="og:title" content="Emissary Elixirs" />
        <meta
          name="og:description"
          content="Small, hand-crafted batches of premium vapour liquid. Based in Cape Town, South Africa."
        />
        <meta name="og:image" content="https://emissaryelixirs.com/logo.jpg" />
      </Helmet>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/health" element={<Health />} />
          <Route path="/:rangeKey/:productKey" element={<Product />} />
          <Route path="/not-found" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
